import React, { useContext } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { Menu as UikitMenu } from '@pancakeswap-libs/uikit'
import config from './config'
import GetTokenPriceUSD from '../../state/getTokenPriceUSD'

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  
  const bbkfiPriceUSD = GetTokenPriceUSD('0xad0ef2895ed5dc33e901bde8a855f4be2c61cb3a', 'BBKFI').toNumber()
  const DtokenPriceUsd = GetTokenPriceUSD('0xa98b99c7a44fc5d5581295899b2839fa48ee181a', '0xefb330c3e3c09c7538f6b83eddb1696f20ad7af4').toNumber()
  const wbbkPriceUsd = GetTokenPriceUSD('0xdbe3a1817db0e530b117ffca2c91b03f21701a7f', 'WBBK').toNumber()

  console.log (DtokenPriceUsd)
  const Dtoken = 1

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      Dtoken={Dtoken}
      cakePriceUsd={bbkfiPriceUSD}
      DtokenPriceUsd={DtokenPriceUsd}
      wbbkPriceUsd={wbbkPriceUsd}
      links={config}
      priceLink="https://dexscreener.com/polygon/0x887fea23cf2469a4e9270cbfe32dbbb613f0c9aa"
      priceLinkDtoken="https://dexscreener.com/polygon/0x40fc9373ee73bbd78bb969a911bf1867a3040239"
      priceLinkWBBK="https://dexscreener.com/polygon/0xdbe3a1817db0e530b117ffca2c91b03f21701a7f"
      {...props}
    />
  )
}

export default Menu

