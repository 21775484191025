import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [  

  {
    sousId: 1,
    tokenName: 'WBBK',
    rewardTokenAddress: '0x2C8a46eF99Cb64928eB3Dd50A925327e02296F63',
    isRewardLp: false,
    stakingTokenName: QuoteToken.WBBK,
    isStakingLp: false,
    stakingLpIndexToken: "WBBK",
    stakingtokenpricepid: 29,
    stakingTokenAddress: '0x2C8a46eF99Cb64928eB3Dd50A925327e02296F63',
    contractAddress: {
      97: '0x88e606a675e173129cfe00fcf172338eb0d3f19d',
      56: '0x88e606a675e173129cfe00fcf172338eb0d3f19d',
      137: '0x88e606a675e173129cfe00fcf172338eb0d3f19d'
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://bitblocksproject.com/',
    router: 'BitBlocks Router',
    harvest: true,
    tokenPerBlock: '0.3',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    DepositFee: 0,
    pricepid: 3,
  },

  {
    sousId: 2,
    tokenName: 'BBKFI',
    rewardTokenAddress: '0xCb9C119d5520A7165F234759578E01F095Aa33C1',
    isRewardLp: false,
    stakingTokenName: QuoteToken.LPWMATICBBKFI,
    isStakingLp: true,
    stakingLpIndexToken: "BBKFI",
    stakingtokenpricepid: 29,
    stakingTokenAddress: '0x887fea23cf2469a4e9270cbfe32dbbb613f0c9aa',
    contractAddress: {
      97: '0x9695690CB5259CD880fA1bD5e9FA93C544200454',
      56: '0x9695690CB5259CD880fA1bD5e9FA93C544200454',
      137: '0x9695690CB5259CD880fA1bD5e9FA93C544200454'
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://bitblocksproject.com/',
    router: 'BitBlocks Router',
    harvest: true,
    tokenPerBlock: '0.001',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    DepositFee: 0,
    pricepid: 3,
  },
 

]

export default pools
