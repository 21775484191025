import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: "https://polygon.bitblocks.finance",
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: "Exchange",
        href: "https://polygon-exchange.bitblocks.finance//#/swap",
      },
      {
        label: "Liquidity",
        href: "https://polygon-exchange.bitblocks.finance//#/pool"
      }
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://polygon.bitblocks.finance/farms',
  },
  {
    label: 'Vote',
    icon: 'GroupsIcon',
    href: 'https://snapshot.org/#/bitblocks.eth',
  },
  {
    label: 'Buy BBKFI',
    icon: 'IfoIcon',
    href: 'https://exchange.bitblocks.finance//#/swap?inputCurrency=MATIC&outputCurrency=0xCb9C119d5520A7165F234759578E01F095Aa33C1',
  },
  {
    label: 'Roadmap',
    icon: 'RoadmapIcon',
    href: 'https://docs.bitblocksproject.com/projects/bitblocks-finance/roadmap',
  },
  {
    label: 'Tools',
    icon: 'NftIcon',
    items: [
      {
        label: '-- BitBlocks Bridge -- BBK <-> wBBK',
        href: 'https://bitblocksproject.com/BRIDGE/?',
      },

    ],
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://docs.bitblocksproject.com/projects/bitblocks-finance',
      },
      {
        label: 'Github',
        href: 'https://github.com/BitBlocksProject',
      },
      {
        label: 'BitBlocksFinance Info',
        href: 'https://polygon-info.bitblocks.finance',
      }

    ],
  },
  {
    label: 'Audited by Certik',
    icon: 'AuditIcon',
    href: 'https://www.certik.org/projects/bitblocksfinance',
  },
  {
    label: 'BitBlocks Website',
    icon: 'MoreIcon',
    href: 'https://bitblocksproject.com/',
  },
]

export default config
