import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import multicall from 'utils/multicall'

const GetTokenPriceUsd = (lpaddress: string, token: string) => {
  const [data, setData] = useState<BigNumber>(new BigNumber(0))
  const [prevAddress, setPrevAddress] = useState<string>('')
  const [prevToken, setPrevToken] = useState<string>('')

  let tokenaddress = token
  if (token === "WMATIC"){
    tokenaddress = "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
  }
  if (token === "BBKFI"){
    tokenaddress = "0xCb9C119d5520A7165F234759578E01F095Aa33C1" 
  }
  if (token === "WBBK"){
    tokenaddress = "0x2C8a46eF99Cb64928eB3Dd50A925327e02296F63"
  }
  if (token === "BAQUA"){ 
    tokenaddress = "0xefb330c3e3c09c7538f6b83eddb1696f20ad7af4"
  }

  if(tokenaddress === null ){
    console.log("ERROR on GetLpPrice, token must be BBKFI-WBNB-BFIRE or wBBK to generate Lpprice")
  }

  useEffect(() => {
    if (lpaddress !== prevAddress || token !== prevToken) {
      setPrevAddress(lpaddress)
      setPrevToken(token)

      const GetData = async () => {
        const lpAddress = lpaddress;
        const calls = [
          {
            address: tokenaddress,
            name: 'balanceOf',
            params: [lpAddress],
          },
          {
            address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
            name: 'balanceOf',
            params: [lpAddress],
          },
        ]

        const [tokenBalance, usdcBalance] = await multicall(erc20, calls)
        const formatedUSDCbalance = new BigNumber(usdcBalance).dividedBy(new BigNumber(10).pow(6))
        const formatedTokenBalance = new BigNumber(tokenBalance).dividedBy(new BigNumber(10).pow(18))
        const tokenPriceUsdc = formatedUSDCbalance.dividedBy(formatedTokenBalance)

        setData(tokenPriceUsdc)
      }

      GetData()
    }
  }, [lpaddress, token, prevAddress, prevToken, tokenaddress])

  return data
}

export default GetTokenPriceUsd