export default {
  cake: {
    56: '0xfC8E68E30350c6603D3d29fCc8E676380C28FCf4',
    97: '',
    137: '0xCb9C119d5520A7165F234759578E01F095Aa33C1',
  },
  bfire: {
    56: '0xe5599a12a8ff06a690ffd2790c54a37c74a43208',
    97: '',
    137: '0xEfB330C3E3C09c7538f6B83EDDB1696F20aD7Af4',
  },
  bbkfi: {
    56: '0xfC8E68E30350c6603D3d29fCc8E676380C28FCf4',
    97: '',
  },
  wbbk: {
    56: '0x9045b0eda6b6a556cf9b3d81c2db47411714f847',
    97: '',
  },
  masterChef: {
    56: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '0xDf3c8B6B0D601Ef7328d4d623B3915ad41d64b70',
    97: '',
  },
  lotteryNFT: {
    56: '0xB1d3b2a6F7bdeaCC2B1e70855971Df368dF3d781',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    137: '0x6337cCC9511331260b44aB72c60C0820e869def9'
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  btcb: {
    56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    97: '',
  },
}
